import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { BodyStyles, ITypographyBody } from '@components/Typography/Body/types';
import styles from './styles/body.module.scss';

/*
 * Typography.Body 2.0
 *
 * Potentially converted to richtext component
 */

const Body = ({
    children,
    className,
    strikethrough,
    style = BodyStyles.BASIC,
    styleDesktop,
    wrapperElement: Wrapper = 'p',
}: ITypographyBody): ReactElement => {
    const classes = clsx(
        styles.default,
        strikethrough && styles.strikethrough,
        styles[`style--${style}`],
        style[`styleDesktop--${styleDesktop}`],
        className,
    );

    return <Wrapper className={classes}>{children}</Wrapper>;
};

export default Body;
