import React, { ReactElement } from 'react';
import { IIcon, IconTypesEnum } from './types';
import Icons from './images/icon.svg';
import Flags from '@components/Icon/images/flag.svg';
import Payments from '@components/Icon/images/payment.svg';
import Socials from '@components/Icon/images/social.svg';

const sourceByType = (type: string | undefined): string => {
    switch (type) {
        case IconTypesEnum.FLAG:
            return Flags;

        case IconTypesEnum.PAYMENT:
            return Payments;

        case IconTypesEnum.SOCIAL:
            return Socials;

        default:
            return Icons;
    }
};

const Icon = ({ className, name, size = '24', type }: IIcon): ReactElement => (
    <svg className={className} name={name} height={size} width={size}>
        <use xlinkHref={`${sourceByType(type)}#${name}`} />
    </svg>
);

export default Icon;
