import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { ITypographyRichtext } from './types';
import DOMPurify from 'isomorphic-dompurify';

import styles from './styles/richtext.module.scss';

const Richtext = ({ className, html, minor, ...rest }: ITypographyRichtext): ReactElement => {
    // There is a hidden feature of DOMPurify to strip target attribute
    // Ref: https://github.com/cure53/DOMPurify/issues/317
    // Since we sometimes need content links to open in new window, and it is controlled from admin
    // we need to whitelist the target attribute for such cases
    // But to play safe we make sure to add a rel=noreferrer, just in case it was forgotten by admin
    // @ts-expect-error isomorphic-dompurify uses regular DOMPurify
    DOMPurify.addHook('afterSanitizeAttributes', (node: HTMLElement) => {
        if (node.hasAttribute('target') && node.getAttribute('target') === '_blank') {
            node.setAttribute('rel', 'noreferrer');
        }
    });

    const sanitizedHtml = DOMPurify.sanitize(html, { ADD_ATTR: ['target'], ADD_TAGS: ['embed', 'iframe'] });

    return (
        <div
            className={clsx(styles.wrapper, minor && styles.minor, className)}
            dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
            {...rest}
        />
    );
};

export default Richtext;
