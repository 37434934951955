import { ReactNode } from 'react';

export enum HeadingTags {
    DIV = 'div',
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
}

export enum HeadingStyles {
    DISPLAY = 'display',
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
}

export enum HeadingAlignment {
    CENTER = 'center',
    RIGHT = 'right',
    JUSTIFY = 'justify',
}

export interface ITypographyHeading {
    className?: string;
    children: ReactNode;
    style: HeadingStyles;
    tag?: HeadingTags;
    align?: HeadingAlignment;
}
