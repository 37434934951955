export enum IconTypesEnum {
    FLAG = 'flag',
    ICON = 'icon',
    PAYMENT = 'payment',
    SOCIAL = 'social',
}

export type TIcons =
    | 'arrow-left'
    | 'arrow-right'
    | 'chevron-left'
    | 'chevron-right'
    | 'chevron-down'
    | 'chevron-up'
    | 'checkmark'
    | 'plus'
    | 'minus'
    | 'minus-circle'
    | 'plus-circle'
    | 'close-circle'
    | 'cart'
    | 'user'
    | 'search'
    | 'full-screen'
    | 'success'
    | 'warning'
    | 'alert-circle'
    | 'percent'
    | 'hamburger'
    | 'close'
    | 'filter'
    | 'contact'
    | 'shipping'
    | 'returns'
    | 'delivery'
    | 'information'
    | 'view'
    | 'hide'
    | 'bin'
    | 'zoom'
    | 'check-circle'
    | 'secure'
    | 'edit-pencil'
    | 'edit'
    | 'lock'
    | 'star_stroke'
    | 'star_half'
    | 'star_filled';

export type TFlags =
    | 'at'
    | 'be'
    | 'ca'
    | 'ch'
    | 'cz'
    | 'de'
    | 'dk'
    | 'es'
    | 'fi'
    | 'fr'
    | 'gb'
    | 'hu'
    | 'ie'
    | 'it'
    | 'nl'
    | 'no'
    | 'pl'
    | 'se'
    | 'us'
    | 'global';

export type TPayments =
    | 'amex'
    | 'applepay'
    | 'bancontact'
    | 'diners'
    | 'dinersclub'
    | 'discover'
    | 'googlepay'
    | 'klarna'
    | 'maestro'
    | 'mastercard'
    | 'paypal'
    | 'vipps'
    | 'visa';
export type TSocial = 'instagram' | 'twitter' | 'youtube' | 'facebook';

export interface IIcon {
    className?: string;
    name: TIcons | TFlags | TPayments | TSocial;
    type?: IconTypesEnum;
    size?: string | number;
}
