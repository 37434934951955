import { ReactNode } from 'react';

export enum BodyStyles {
    BOLD = 'bold',
    BASIC = 'basic',
    MINOR = 'minor',
}

export interface ITypographyBody {
    style?: BodyStyles;
    styleDesktop?: BodyStyles;
    className?: string;
    children: ReactNode;
    strikethrough?: boolean;
    wrapperElement?: keyof JSX.IntrinsicElements;
}

export interface ITypographyBodyAem {
    text?: string;
}
