import React from 'react';
import clsx from 'clsx';
import { HeadingTags, ITypographyHeading } from '@components/Typography/Heading/types';
import styles from './styles/heading.module.scss';

/**
 * Typography.Heading 2.0
 *
 * @param tag A html tag of the element
 * @param children Text to display
 * @param style One of the predefined heading style
 * @param className Custom class
 * @constructor
 */

const Heading = ({ align, children, className, style, tag: Component = HeadingTags.DIV }: ITypographyHeading) => {
    const currentStyle = clsx(styles.default, styles[`style--${style}`], align && styles[`align--${align}`], className);

    return <Component className={currentStyle}>{children}</Component>;
};

export default Heading;
